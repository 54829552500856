import axios from '../config/axios';

export const url = '/api/subscription';

export const addSuscripcionCompany = ({
  name, email
}) => new Promise((resolve, reject) => {
  if (name && email) {
    axios.post(`${url}/add/company`, {
      name, email
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!name) {
    reject({
      status: 'error',
      info: 'Nombre es requerido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Correo es requerido'
    });
  }
});