import React from 'react';
import logo from '../assets/logoBlanco.png';

const Footer = () => (
  <footer className="bg-dark py-7">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-5 col-xl-4 me-auto mb-5 mb-lg-0">
          <div className="footer-logo h2 text-primary mb-0 font-w-7 pl-4" href="#">
            <img alt="Logo" src={logo} style={{width: '120px'}} className="ml-4" />
          </div>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a target="_blank" className="border rounded px-2 py-1 text-light" href="https://www.facebook.com/profile.php?id=100074220339642">
                <i className="la la-facebook"></i>
              </a>
            </li>
            {/* <li className="list-inline-item"><a className="border rounded px-2 py-1 text-light" href="#"><i className="la la-dribbble"></i></a>
            </li> */}
            <li className="list-inline-item">
              <a target="_blank" className="border rounded px-2 py-1 text-light" href="https://www.instagram.com/argesware/">
                <i className="la la-instagram"></i>
              </a>
            </li>
            {/* <li className="list-inline-item">
              <a target="_blank" className="border rounded px-2 py-1 text-light" href="#">
                <i className="la la-twitter"></i>
              </a>
            </li> */}
            {/* <li className="list-inline-item"><a className="border rounded px-2 py-1 text-light" href="#"><i className="la la-linkedin"></i></a>
            </li> */}
          </ul>
        </div>
        <div className="col-12 col-lg-6 col-xl-7">
          <div className="col-12 col-sm-12 mt-6 mt-sm-0">
              <h5 className="mb-4 text-white">Nuestra dirección</h5>
              <div className="mb-3">
              <p className="mb-0 text-light">Col. Las Hadas, Tegucigalpa, Honduras</p>
            </div>
            <div className="mb-3">
              <a className="btn-link text-light" href="mailto:themeht23@gmail.com"> argesware@gmail.com</a>
            </div>
            {/* <div>
              <a className="btn-link text-light" href="tel:+912345678900">+91-234-567-8900</a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="row my-5">
        <div className="col"><hr className="m-0"/></div>
      </div>
      <div className="align-items-center">
        <div className="text-light text-center">
          Copyright - Argesware ©2021 All rights reserved
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;
