import React from 'react';
import logo from '../assets/logo.png';

const Navbar = () => (
<div id="header-wrap" className="position-absolute w-100 z-index-1">
    <div className="container">
      <div className="row">
        <div className="col"> 
          <nav className="navbar navbar-expand-lg navbar-light justify-content-start">
            <a className="navbar-brand logo text-primary mb-0 font-w-7 me-6" href="index.html">
              {/* W<span className="text-dark font-w-4">inck.</span> */}
              <img alt="Logo" src={logo} style={{width: '90px'}} />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav me-auto">
                <li className="nav-item dropdown">
                  <a className="nav-link  active" href="#inicio">Inicio</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">Sobre Nosotros</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#testimonies">Testimonios</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#clients">Nuestros clientes</a>
                </li>
              </ul>
            </div>
          </nav>
          
        </div>
      </div>
    </div>
  </div>
);

export default Navbar;